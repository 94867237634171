<template>
  <div class="mt-5 mb-5">
    <b-container>
      <div>
        <h2 class="font-weight-bolder text-colorBlue">
          Medical Tourism
          <span class="text-colorGreen">Insurance </span>
        </h2>
      </div>
      <b-row>
        <b-col
          md="12"
          lg="12"
          v-for="(insurance, index) in insurances"
          :key="index"
          class="d-flex flex-column justify-content-between mt-2 border border-colorGray rounded"
          :class="{ 'bg-colorGrey rounded': isHovered === index }"
          @mouseover="isHovered = index"
          @mouseleave="isHovered = null"
        >
          <b-row class="d-flex align-items-start p-2">
            <b-col md="4">
              <img
                :src="insurance.display_image"
                class="img-fluid rounded p-0"
              />
            </b-col>
            <b-col md="4" class="d-flex flex-column justify-content-between">
              <div class="h-75">
                <h5
                  class="font-weight-bolder text-colorText"
                  style="font-size: 1.7rem"
                >
                  {{ insurance.name }}
                </h5>
                <p>{{ insurance.intro }}</p>
              </div>
            </b-col>
            <b-col md="2">
              <p
                class="font-weight-bolder text-colorText m-0 p-0"
                style="font-size: 1.3rem"
              >
                starting from
              </p>
              <h2 class="font-weight-bolder text-colorGreen">
                {{ insurance.amount }}$
              </h2>
            </b-col>
          </b-row>
          <!-- <div class="text-center">
            <b-button
              rounded
              :variant="isInCart(insurance) ? 'colorBlue' : 'colorGreen'"
              size="md"
              class="font-weight-bolder mb-sm-1"
              @click="handleCartAction(insurance)"
            >
              {{ isInCart(insurance) ? 'View Cart' : 'Add to Cart' }}
            </b-button>
          </div> -->
        </b-col>
      </b-row>

      <div class="mt-5">
        <h2 class="font-weight-bolder text-colorBlue pt-5">
          Policy
          <span class="text-colorGreen">Terms and Conditions </span>
        </h2>
        <ul class="font-weight-bold text-colorGray">
          <li>Coverage valid for 90 days from policy issuance.</li>
          <li>Policy cancellation for refund is not permitted.</li>
          <li>
            Company reserves the right to accept or reject coverage requests
            without reason.
          </li>
        </ul>
      </div>
      <div class="mt-5">
        <h2 class="font-weight-bolder text-colorBlue">Eligibility</h2>
        <ul class="font-weight-bold text-colorGray">
          <li>
            All foreign nationals visiting Pakistan for medical tourism are
            eligible.
          </li>
          <li>Policy issuance up to 50 years of age.</li>
          <li>Separate premiums for elderly visitors aged 51-69.</li>
        </ul>
      </div>
      <div class="mt-5">
        <h2 class="font-weight-bolder text-colorBlue">
          Product <span class="text-colorGreen">Exclusions </span>
        </h2>
        <ul class="font-weight-bold text-colorGray">
          <li>Cosmetics, aesthetics, and related treatments.</li>
          <li>
            Cost of limbs, prosthetics, hearing aids, crutches, dentures, and
            supporting equipment.
          </li>
          <li>Drug abuse/self-inflicted injuries.</li>
          <li>Mental illness or psychiatric treatment.</li>
          <li>Fertility, impotence, sterilization procedures or treatments.</li>
          <li>War, invasion, civil commotion.</li>
        </ul>
      </div>
      <div class="mt-5 text-center">
        <h2 class="font-weight-bolder text-colorBlue">
          Start Your
          <span class="text-colorGreen">Medical Tourism Journey </span>
          with Confidence!
        </h2>
        <p class="font-weight-bold text-colorGray">
          Protect your health and finances with our comprehensive supplementary
          medical tourism insurance. Contact us today to customize your plan and
          secure peace of mind for your medical journey.
        </p>
      </div>
    </b-container>
  </div>
</template>

<script>
import VueSelectPaginated from "@/components/ui/VueSelectPaginated.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import util from "@/util.js";
import { mapActions, mapState } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    VueSelectPaginated,
  },
  mixins: [util],

  data() {
    return {
      insurances: [],
      isHovered: null,
    };
  },
  computed: {
    ...mapState({
      cart: (state) => state.appData.cart, // Assuming your cart is stored in appData module of Vuex
    }),
  },
  async mounted() {
    try {
      // this.initializeCart();

      const res = await this.getInsurancePublic({});
      if (res.status === 200) {
        this.insurances = res.data;
        console.log(this.insurances);
      }
    } catch (error) {
      this.displayError(error);
    }
  },
  methods: {
    ...mapActions({
      getInsurancePublic: "appData/getInsurancePublic",
      addToCart: "appData/addToCart",
      removeFromCart: "appData/removeFromCart",
      // initializeCart: "appData/initializeCart",
    }),
    addCart(item) {
      this.addToCart({
        id: `${item.id}_${this.cartItemType.INSURANCE}`,
        name: item.name,
        itemId: item.id,
        price: item.amount,
        type: this.cartItemType.INSURANCE,
        quantity: 1,
        numberOfDays: 1,
      });
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Item added to cart successfully",
            variant: "success",
            icon: "BellIcon",
          },
        },
        {
          position: "top-right",
        }
      );
    },
    handleCartAction(item) {
      if (this.isInCart(item)) {
        this.$router.push({ name: "Cart" }); // Assuming you have a route named 'Cart'
      } else {
        this.addCart(item);
      }
    },
    isInCart(item) {
      const cartItemId = `${item.id}_${this.cartItemType.INSURANCE}`;
      return this.cart.some((cartItem) => cartItem.id === cartItemId);
    },
  },
};
</script>

<style scoped>
.my-border {
  border-bottom: 8px solid #06bd46;
}
.hover-img {
  display: none;
}
.image-container-hover:hover .hover-img {
  display: block;
  margin: 0 auto;
}

.image-container-hover:hover .base-img {
  display: none;
}
</style>
